import Router from "next/router";
import React from "react";
import { FrontPagesFooter } from "../components/front-pages/commons";
import ErrorDisplay from "../components/front-pages/error-display";
import FrontPagesLayout from "../components/front-pages/front-pages-layout";
import { PageTags } from "../components/seo-tags";
import PagesLoaderContext from "../contexts/page-loader-context";

const pageTags: PageTags = {
  title: "Catlog - Page Not Found",
  description: "The page you are trying to access doesn't exist or has been moved",
  pageUrl: "/404",
  image: "https://res.cloudinary.com/catlog/image/upload/v1671183044/seo-banners/Not_Found_Banner.png",
};

const NotFound = () => {
  return <NotFoundMain />;
};
export const NotFoundMain = () => {
  return (
    <PagesLoaderContext.Provider>
      <FrontPagesLayout hideAnimation tags={pageTags}>
        <main>
          <ErrorDisplay
            description={
              <>
                The page you are trying to access <br />
                doesn’t exist or has been moved.
              </>
            }
            onClick={() => Router.push("/")}
            btnCta="Go back home"
          >
            Oops, Page <br /> not found!
          </ErrorDisplay>
          <FrontPagesFooter />
        </main>
      </FrontPagesLayout>
    </PagesLoaderContext.Provider>
  );
};

export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}

export default NotFound;

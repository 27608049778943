import classNames from "classnames";
import React from "react";
import { getStyles } from "../../assets/js/utils/functions";
import { getSizedImage } from "../../assets/js/utils/utils";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";
import LazyImage from "../lazy-image";
import { AppBtn } from "../ui/buttons";
import { FrontPagesNavigation } from "./commons";

interface CTAProps {
  className?: string;
  description?: React.ReactElement;
  btnCta: string;
  onClick: VoidFunction;
}

const ErrorDisplay: React.FC<CTAProps> = ({ className, description, btnCta, onClick, children }) => {
  const { screen } = useScreenSize();
  return (
    <section className={classNames("overflow-hidden relative cta front-header front-header--about-us", className)}>
      <FrontPagesNavigation />
      <div className="body-padding">
        <div className="mt-16 container relative">
          <div className="pb-[65vw] sm:!pb-45 relative z-50">
            <h3 className="heading-two text-black font-bold">{children}</h3>

            <div className="hide-for-observer">
              <p className="mt-2.5 sub-text text-dark-secondary sm:max-w-md md:max-w-none">{description}</p>
              <AppBtn className="mt-8 sm:mt-10 lg:mt-12.5" size="lg" onClick={onClick}>
                {btnCta}
                {/* prettier-ignore */}
                <svg viewBox="0 0 12 12" fill="none" className="ml-1.5 w-3 transform transition-all ease-out duration-100 group-hover:translate-x-0.5">
                  <path d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z" fill="currentColor" strokeWidth={1.5} />
                </svg>
              </AppBtn>
            </div>
          </div>
          <LazyImage
            showLoader={false}
            src={getSizedImage(
              "https://res.cloudinary.com/catlog/image/upload/v1670357114/illustrations/storefront.webp",
              { [BreakPoint.xs]: "w_700", default: "w_1000" },
              screen
            )}
            alt="Store Illustration"
            classesBeforeLoad="block w-[120%] sm:mx-0 sm:w-[82%] md:w-[75%] lg:w-[60%] -bottom-[8%] absolute -right-[10%] sm:-right-[10%] lg:-right-[12%] sm:-bottom-[6%]  md:-bottom-[10%] sm:left-[unset] max-w-[850px] min-h-[800px]"
            className="block w-[142%] sm:mx-0 sm:w-[90%] md:w-[87%] lg:w-[72%] -bottom-[8%] absolute -right-[24%] sm:-right-[12%] lg:-right-[15%] xl:-right-[9%] sm:-bottom-[8%]  md:-bottom-[9%] lg:-bottom-[9%] sm:left-[unset] lg:min-w-[750px] max-w-[900px]"
          />
        </div>
      </div>
    </section>
  );
};

export default ErrorDisplay;
